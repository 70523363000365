import { ApiClient } from "Tools/api/client";
import { processResponse } from "Tools/api/helpers";


/**
 * Request auth credentials. Takes care of setting the value in localstorage
 * @param credentials account credentials used to sign in
 * @returns A promise with value of `[token?, errorMessage?]` 
 */
export const requestToken = async (credentials: UserCredentials) => {

        let { data: token, error, originalError, status } = processResponse(await ApiClient.post<TokenPayload>('token/', credentials));
        return { token, error, originalError, status }
}


/**
 * Loads a user using the `refresh` token from the server
 * @param loadAccessToken will request the server for a new access token
 * when the `true`
 * @returns 
 */
export const loadUser = async () => {
        let user: User | null = null, error: string | null = null
        try {
                ({ data: user, error } = processResponse((await ApiClient.get<User>('index/users/get_current_user/'))))
                // if (user && /\@syngenta\.com^/i.test(user.email) &&  (process.env.REACT_APP_EXTERNAL_HOSTNAME?.indexOf(window.location.host)||-1) ==-1 ) {
                //         window.location.href = process.env.REACT_APP_INTERNAL_HOSTNAME as any
                // }
        } catch {
                user = null
                error = 'Authentication required'
        }
        return { user, error }
}

export const sendPasswordResetTokenToEmail = async (email: string) => {
        return processResponse(await ApiClient.post('password-reset/', { email }))
}
export const verifyPasswordResetToken = async (token: string) => {
        return processResponse(await ApiClient.post('password-reset/validate_token/', { token }))
}


export const submitNewPassword = async (token: string, password: string) => {
        return processResponse(await ApiClient.post('password-reset/confirm/', { token, password }))
}