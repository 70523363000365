import { ApiClient } from "Tools/api/client";
import { processResponse, processResultsResponse } from "Tools/api/helpers";

export const createDevice = async (data: any) => {
  return processResponse(await ApiClient.post("digital-equipment/devices/", data));
};

export const getDevices = async <T = DetailedDeviceInformation>(params = {} as any, subpath = null as strnull_t, subpathData?: any) =>
  processResultsResponse<T[]>(await ApiClient[subpathData ? 'post' : 'get'](`digital-equipment/devices/${subpath ? subpath + '/' : ''}`, subpathData || { params }));

export const deleteDevice = async (id: number) => {
  return processResponse(await ApiClient.delete(`digital-equipment/devices/${id}/`));
};
export const getStorageLocations = async (params = {} as any) => {
  return processResultsResponse(await ApiClient.get("digital-equipment/storage-location/", { params }));
};

export const getAddDeviceRequests = async <T = EquipmentStockAddDeviceRequest>(params = {} as any, subpath = null as strnull_t, subpathData?: any) =>
  processResultsResponse<T[]>(await ApiClient[subpathData ? 'post' : 'get'](`digital-equipment/available-request/${subpath ? subpath + '/' : ''}`, subpathData || { params }));


export const updateAddDeviceRequest = async (id: any, data: any) => {
  return processResponse(await ApiClient.patch(`digital-equipment/available-request/${id}/`, data))
}

export const postAddDeviceRequest = async (data: any) => {
  return processResponse(await ApiClient.post("digital-equipment/available-request/", data));
};

export const acceptDeviceRequest = async (id: any, data: any) => processResponse(await ApiClient.patch(`digital-equipment/available-request/${id}/accept/`, data))


export const getUsageHistory = async (params = {} as any) => {
  return processResultsResponse<EquipmentUsageInformation[]>(await ApiClient.get("digital-equipment/usage-information/", { params }));
};

export const updateDevice = async (id: number, data: any) => processResponse(await ApiClient.patch(`digital-equipment/devices/${id}/`, data));

export const getDeviceRequests = async (params = {} as any, subpath?: string) => {
  return processResultsResponse<DeviceRequest[]>(await ApiClient.get(`digital-equipment/request/${subpath ? subpath + '/' : ''}`, { params }));
};

export const createDeviceRequest = async (data: any) => {
  return processResponse(await ApiClient.post("digital-equipment/request/", data));
};
export const updateDeviceRequest = async (id: number, data: any) => processResponse(await ApiClient.patch(`digital-equipment/request/${id}/`, data));

export const addDeviceStorageLocation = async (data: any) => {
  return processResponse(await ApiClient.post("digital-equipment/storage-location/", data));
};

/**Use for both DELETE and GET deleted devices */
export const deleteDeviceAction = async (id: any, params = {} as any) => processResultsResponse<DetailedDeviceInformation[]>(await ApiClient[id ? 'delete' : 'get'](`digital-equipment/devices/soft_deletion/${id ? '?id=' + id : ''}`, { params }))


export const getSensorCategories = async (params = {} as any) => {
  return processResultsResponse<EquipmentSensorCategory[]>(await ApiClient.get("digital-equipment/sensor-category/", { params }));
}

export const getDeviceTypes = async (params = {} as any) => {
  return processResultsResponse<EquipmentSensorType[]>(await ApiClient.get("digital-equipment/sensor-type/", { params }));
}

export const getManufacturers = async (params = {} as any) => {
  return processResultsResponse<EquipmentDeviceManufacturer[]>(await ApiClient.get("digital-equipment/device-manufacturer/", { params }));
}

export const getEquipmentSettingsData = async (params = {} as any) => {
  return processResultsResponse<any>(await ApiClient.get(""));
}

export const getDeviceModels = async (params = {} as any) => {
  return processResultsResponse<EquipmentDeviceModel[]>(await ApiClient.get("digital-equipment/device-model/", { params }));
}

export const getUsers = async (params = {} as any) => { return processResultsResponse(await ApiClient.get("index/users/", { params })); }


export const createOrUpdateSensorCategory = async (data: any, editingId = 0) => {
  let url = `digital-equipment/sensor-category/${editingId ? editingId + '/' : ''}`
  return processResponse(await ApiClient[editingId ? 'patch' : 'post'](url, data));
};

export const deleteSensorCategory = async (id: number) => {
  return processResponse(await ApiClient.delete(`digital-equipment/sensor-category/${id}/`));
};


export const createOrUpdateDeviceManufacturer = async (data: any, editingId = 0) => {
  let url = `digital-equipment/device-manufacturer/${editingId ? editingId + '/' : ''}`
  return processResponse(await ApiClient[editingId ? 'patch' : 'post'](url, data));
};

export const deleteDeviceManufacturer = async (id: number) => {
  return processResponse(await ApiClient.delete(`digital-equipment/device-manufacturer/${id}/`));
};


export const createOrUpdateDeviceType = async (data: any, editingId = 0) => {
  let url = `digital-equipment/sensor-type/${editingId ? editingId + '/' : ''}`
  return processResponse(await ApiClient[editingId ? 'patch' : 'post'](url, data));
};

export const deleteDeviceType = async (id: number) => {
  return processResponse(await ApiClient.delete(`digital-equipment/sensor-type/${id}/`));
};


export const createOrUpdateDeviceModel = async (data: any, editingId = 0) => {
  let url = `digital-equipment/device-model/${editingId ? editingId + '/' : ''}`
  return processResponse(await ApiClient[editingId ? 'patch' : 'post'](url, data));
};


export const deleteDeviceModel = async (id: number) => {
  return processResponse(await ApiClient.delete(`digital-equipment/device-model/${id}/`));
};


export const getOrCreateOrUpdateDeviceViewPreferences = async <T = ColumnVisibilityModel>(params: any, subpath: strund_t = undefined, data: any = null) => {
  return processResponse<T>(await ApiClient[data ? 'post' : 'get'](`digital-equipment/preferences-all_devices-view/${subpath ? subpath + '/' : ''}`, data || { params }));
}

export const createOrUpdateCountryDeviceViewPreferences = async <T = ColumnVisibilityModel>(id: any, subpath: strund_t = undefined, data: any = null) => {
  return processResponse<T>(await ApiClient['post'](`digital-equipment/preferences-admin-all_devices-view/${subpath ? subpath + '/' : ''}`, { id: id, data }));
}

export const getCountryDeviceViewPreferences = async <T = ColumnVisibilityModel>(params: any, subpath: strund_t = undefined) => {
  return processResultsResponse<T[]>(await ApiClient.get(`digital-equipment/preferences-admin-all_devices-view/${subpath ? subpath + '/' : ''}`, { params }));
}