
export const listenToEvent=<K extends keyof HTMLElementEventMap>(elementId:string,eventName:K,handler:form_event_func_t)=>{
    document.getElementById(elementId)?.addEventListener(eventName,(e)=>handler(e as any))
}

export function getCookie(cookieName:string) {
    let cookies = document.cookie;
    let cookieArray = cookies.split("; ");
 
    for (let i = 0; i < cookieArray.length; i++) {
       let cookie = cookieArray[i];
       let [name, value] = cookie.split("=");
      
       if (name === cookieName) {
          return decodeURIComponent(value);
       }
    }
    
    return null;
 }