import { Dispatch, SetStateAction, createContext } from "react"

export interface IProcessingContext{
    
    
    $validationFailed:false|string
    $dataSavedSuccesfully:boolean

    $dataSaveFailed:string|false
    $errorOccured:string|false
    $savingData:boolean
    $isLoading:boolean
}

export const PROCESSING_CONTEXT_DEFAULT={
    $validationFailed: "",
    $dataSavedSuccesfully: false,
    $dataSaveFailed: "",
    $errorOccured: "",
    $savingData: false,
    $isLoading: false
}

export const ProcessingContext=createContext<IProcessingContext>({
    ...PROCESSING_CONTEXT_DEFAULT
})


const TICK={
    tick:0,
    setTick:((v:number)=> v) as Dispatch<SetStateAction<number>>
}
export const TickContext=createContext(TICK)