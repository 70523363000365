import { Stack, Text, ProgressIndicator } from "@fluentui/react"
import { PropsWithChildren, Suspense } from "react"

type Props ={indicatorWidth?:any} & PropsWithChildren

export const SuspenseLoadingUI = ({ children,indicatorWidth }: Props) => {
    return <Suspense  {...{children}} fallback={
        <Stack verticalAlign="center" horizontalAlign="center" style={{width:'100%',height:'100%', paddingTop:'10rem'}}>
                <ProgressIndicator styles={{root:{width:indicatorWidth||'30rem'}}} label='Loading...'/>
        </Stack>
    }/>
}