import { createContext } from "react"

export type str_t=undefined|string
export type variant_t='over'|'bottom-left'|undefined
export type type_t='error'|'loading'|'success'|undefined


export interface IGLobalDialog{
        variant:variant_t,
        type:type_t,
        message:str_t,
        active:boolean,
        icon:str_t,
        activeChannel:str_t,
    
        show:(channel:string,message:str_t,type:type_t,variant?:variant_t,icon?:str_t)=>void,
        close:(channel:string|null)=>void
    
}

export const GlobalDiallogContext=createContext({
    variant:undefined,
    type:undefined,
    message:undefined,
    active:false,
    icon:undefined,
    activeChannel:undefined,
    

    show:()=>void 0,
    close:()=>void 0
} as IGLobalDialog)