import { getUsers } from "Data/equipment/api";
import { ApiClient } from "Tools/api/client";
import { processResponse, processResultsResponse } from "Tools/api/helpers";

// Load data from the api
export const getTeams = async (params: any = {}, alternateUrl: string | null = null) => {
  return processResultsResponse<Team[]>(await ApiClient.get(alternateUrl || "frost-forecast/teams/", { params }));
};
export const getCountries = async <T = Country>(params: any = {}, subPath?: string) => {
  return processResultsResponse<T[]>(await ApiClient.get(`index/countries/${subPath ? subPath + '/' : ''}`, { params }));
};

export const getAllUsers = async <T = User>(params: any = {}, nextPageUrl: string | null = null, subpath: strnull_t = null, postData = null as any) => {
  return processResultsResponse<T[]>(await ApiClient[postData ? 'post' : 'get'](nextPageUrl || `index/users/${subpath ? subpath + '/' : ''}`, postData || { params }));
};

export const getOrCreateCROVendors = async (params: any = {}, subpath = null as strnull_t, postData = null as any) => processResultsResponse<CROVendor[]>(await ApiClient[postData ? 'post' : 'get']("index/cro-vendors/", postData || { params }));
export const updateCROVendors = async (id: any, data: any = null) => processResultsResponse<CROVendor[]>(await ApiClient[data ? 'patch' : 'delete'](`index/cro-vendors/${id}/`, data));

export const getOrCreateFROSTCodes = async (params: any = {}, subpath = null as strnull_t, postData = null as any) => processResultsResponse<FROSTCode[]>(await ApiClient[postData ? 'post' : 'get']("index/frost-codes/", postData || { params }));
export const updateFROSTCodes = async (id: any, data: any = null) => processResultsResponse<FROSTCode[]>(await ApiClient[data ? 'patch' : 'delete'](`index/frost-codes/${id}/`, data));

export const getCROUsers = async (params: any = {}) => {
  return getUsers(params)
};


export const getTrialCodes = async (params = {} as any) => {
  return processResultsResponse(await ApiClient.get("frost-ordering/data/get_trial_codes/", { params }));
};

export const createUser = async (data: any, params = {}) => {
  return processResponse(await ApiClient.post("index/users/", data, { params }));
};

export const updateUser = async (id: number, data: any, params = {}) => {
  return processResponse<User>(await ApiClient.patch("index/users/" + id + "/", data, { params }));
};

export const getUserTypes = async (params = {} as any) => {
  return processResultsResponse<id_name_pair_t[]>(await ApiClient.get("index/users/get_user_role_options/", { params }));
};


export const getProducts = async (params: any = {}) => {
  return processResultsResponse<StandardForecastProduct[]>(await ApiClient.get("frost-forecast/product-data/", { params }));
};

export const getTPProtocolList = async (params = {} as any) => {
  return processResultsResponse(await ApiClient.get("frost-ordering/data/get_TPProtocol_list/", { params }));
};

export const getChemicalProductPurposeChoices = async () => {
  return processResultsResponse<string[][]>(await ApiClient.get("chemicals-stock/products/get_purpose_choices/"));
};

export const getCurrentDate = async () => processResponse<string>(await ApiClient.get("index/get_current_date/"));



export const getModuleNameForAdmin = async () => {
  return processResultsResponse<{ [k: string]: string }[]>(await ApiClient.get('index/users/get_apps_managed_by_admin/'))
}

export const getRoleFiltersForAdmin = async (params = {} as any) => processResultsResponse<string[]>(await ApiClient.get('index/users/get_admin_filter_options/', { params }))


export const uploadFile = async (url: string, file: File, extras = {} as any) => {
  let data = new FormData
  for (let k in extras)
    data.append(k, extras[k])
  data.append('file', file)
  return processResponse(await ApiClient.post(url, data, { headers: { "Content-Type": 'multipart/form-data' } }))
}

