import { useAuthContext, useAuthProvider } from "Data/auth/hooks"
import { Navigate, Route, RouteProps } from "react-router-dom"
import { LINKS } from "Tools/navigation"
import { PropsWithChildren, useContext } from "react"

/**
 * Prevent authentication to this view if the session is not authenticated
 * @param param0 
 * @returns 
 */
export const ProtectedView = ({ children }: PropsWithChildren) => {
    let { user, loading } = useAuthContext()
    return (
        <>
            {(loading && <></>)
                || (user && children)
                ||
                <Navigate to={LINKS.login} replace />
            }
        </>
    )
}