import { PropsWithChildren } from "react"
import { useAuthContext } from "Data/auth/hooks"
import { Navigate } from "react-router-dom"
import { LINKS } from "Tools/navigation"

/**
 * Prevent navigation to this view if the session
 * is authenticated.
 */
export const NoAuthView = ({ children }: PropsWithChildren) => {
    let { user, loading } = useAuthContext()
    return (
        <>
            {
                (loading && <></>) // render blank screen if loading
                || (!user && children)
                || <Navigate to={LINKS.home} replace />
            }
        </>
    )
}