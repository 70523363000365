
/**
 * Links to use with react router navigation
 */
export interface IMenuItem {
  title: string;
  link?: string;
  id?: number;
  iconName?: string;
  linkBase?: string;
  permissions?: string[];
  anyPermissions?: string[];
  items?: IMenuItem[];
}
export namespace LINKS {
  export const home = "/"; //
  export const VOID = "";
  export const login = "/login"; //  /login
  export const ssoLogin = "/sso-sign-in"; //  /login
  export const accountRecovery = "/recover-account";
  export const $login = "login"; //  /login
  export const $SSOSignIn = "sso-sign-in"; //  /login
  export const $accountRecovery = "recover-account"; //  /login
  export const $settings = "settings"; //  /settings
  export const $people = "people"; //  /people
  export const people = '/people'
  export const $frost = "frost"; //  /frost
  export const $utilities = "utilities"; //  // utils
  export const $chemicalStock = "chemical-stock"; //  /frost
  export const $equipmentStock = "equipment-stock";
  export const $seedcare = 'seedcare'
  export const $activities = 'activities'

  export namespace frost {
    export const $forecast = "forecast";
    export const $reservation = "reservation";
    export const $ordering = "ordering";
    export const $shipping = "shipping";
    export const $tracking = "tracking";

    export namespace forecast {
      export const $add = "add";
      export const $list = "list";
      export const list = "/frost/forecast/list";
      export const add = "/frost/forecast/add";
    }
    export namespace reservation {
      const PREFIX = '/frost/reservation/'
      export const $report = "report";
      export const report = `${PREFIX}report`;
      export const $sampleManagement = "sample-management";
      export const sampleManagement = `${PREFIX}${$sampleManagement}`;
    }
    export namespace ordering {
      const PREFIX = "/frost/ordering/"
      export const $assign = "assign";
      export const $create = "create";
      export const $report = "report";
      export const $tdmanagement = "trial-demand-managemant";
      export const $flaggedData = "flagged-data";
      export const assign = `${PREFIX}${$assign}`;
      export const create = `${PREFIX}${$create}`;
      export const report = `${PREFIX}${$report}`;
      export const tdmanagement = `${PREFIX}${$tdmanagement}`;
      export const flaggedData = `${PREFIX}${$flaggedData}`;
    }

    export namespace tracking {
      export const $report = "report";
      export const report = `/${$frost}/${$tracking}/${$report}`;
    }

    export namespace shipping {
      export const $report = 'report'
      export const report = "/frost/shipping/report";
    }
  }


  export namespace activities {
    export const $planning = 'planning'
    export namespace planning {
      export const $calendar = 'calendar'
      export const $overview = 'overview'
      export const calendar = `/${$activities}/${$planning}/${$calendar}`
      export const overview = `/${$activities}/${$planning}/${$overview}`
    }
  }

  export namespace chemicalStock {
    export const $branches = 'branches'
    export const $addProduct = "add-product";
    export const $viewProducts = "view-products";
    export const $productOrders = "order";
    export const branchesList = "countries-and-branches";
    export const $tracking = 'tracking'

    export const addProduct = `/${$chemicalStock}/${$addProduct}`;
    export const viewProducts = `/${$chemicalStock}/${$viewProducts}`;
    export const productOrders = `/${$chemicalStock}/${$productOrders}`;


    export namespace tracking {
      export const $gepReport = 'gep-report'
      export const gepReport = `/${$chemicalStock}/${$tracking}/${$gepReport}`
    }

    export namespace branches {
      export const $list = 'list'
      export const $members = 'members'
      export const list = `/${$chemicalStock}/${$branches}/${$list}`
      export const members = `/${$chemicalStock}/${$branches}/${$members}`
    }
  }

  export namespace equipmentStock {
    export const $equipmentStock = "equipment-stock";
    export const $deviceUsage = "achieves";
    export const $deviceApproval = "device-approval";
    export const viewEquipmentStock = "/equipment-stock/devices/view-devices";
    export const deviceUsage = "/equipment-stock/achieves";
    export const deviceApproval = "/equipment-stock/device-approval";
    export const $devices = "devices";
    export namespace devices {
      export const $deviceMasterList = "device-master-list";
      export const $deviceRequests = "device-requests";
      export const $assignedDevices = "assigned-devices";
      export const $allDevices = "all-devices";
      export const deviceMasterList = "/equipment-stock/devices/device-master-list";
      export const deviceRequests = "/equipment-stock/devices/device-requests";
      export const assignedDevices = "/equipment-stock/devices/assigned-devices";
      export const allDevices = "/equipment-stock/devices/all-devices";
    }
  }

  export namespace seedCare {
    export const $seedTreatment = 'seed-treatment'
    export const $infectedSeedsDatabase = 'infected-seeds-database'
    export namespace seedTreatment {
      export const $createRequests = 'create-requests'
      export const $rtmRequestTrial = 'rtm-request-trial'
      export const $requestOverview = 'request-overview'
      export const $assignmentsList = 'assignments-list'
      export const $stfShipping = 'stf-shipping'
      export const $stfProtocolsPerTrial = 'stf-protocols-trial'
      export const $blockedDemandIDs = 'blocked-demand-ids'
      export const $treatmentPlan = 'treatment-plan'
      export const createRequests = `${$seedcare}/${$seedTreatment}/${$createRequests}`
      export const rtmRequestTrial = `${$seedcare}/${$seedTreatment}/${$rtmRequestTrial}`
      export const requestOverview = `${$seedcare}/${$seedTreatment}/${$requestOverview}`
      export const assignmentsList = `${$seedcare}/${$seedTreatment}/${$assignmentsList}`
      export const stfShipping = `${$seedcare}/${$seedTreatment}/${$stfShipping}`
      export const stfProtocolsPerTrial = `${$seedcare}/${$seedTreatment}/${$stfProtocolsPerTrial}`
      export const blockedDemandIDs = `${$seedcare}/${$seedTreatment}/${$blockedDemandIDs}`
      export const treatmentPlan = `${$seedcare}/${$seedTreatment}/${$treatmentPlan}`
    }


    export namespace infectedSeedsDatabase {
      export const $management = 'management'
      export const management = `${$seedcare}/${$infectedSeedsDatabase}/${$management}`
    }

  }


  export namespace utilities {
    export const $dataQuality = "data-quality"; //  
    export const $seedcareDeepDive = 'seedcare-deep-dive'

    export namespace seedcareDeepDive {
      export const $submit = 'submit'
      export const $history = 'history'
      export const submit = `/${$utilities}/${$seedcareDeepDive}/${$submit}`
      export const history = `/${$utilities}/${$seedcareDeepDive}/${$history}`

    }

    export namespace dataQuality {
      export const $ruleBook = 'rule-book'
      export const $auditTable = 'audit-table'

      export const ruleBook = `/${$utilities}/${$dataQuality}/${$ruleBook}`
      export const auditTable = `/${$utilities}/${$dataQuality}/${$auditTable}`
    }
  }

  export namespace settings {
    export const $application = "application"; //
    export const $userAdministration = "user-administration";
    export const $myAccount = "my-account";
    export const userAdministration = "/settings/user-administration";
    export const myAccount = "/settings/my-account";
    export namespace application {
      const PREFIX=`/${$settings}/${$application}/`
      export const $frost = "frost";
      export const $equipmentStock = "equipment-stock";
      export const $chemicalsStock = "chemicals-stock";
      export const $developerConsole = "developer-console";
      export const frost = `${PREFIX}frost`;
      export const equipmentStock = `${PREFIX}${$equipmentStock}`;
      export const chemicalsStock = `${PREFIX}${$chemicalsStock}`;
      export const developerConsole = `${PREFIX}${$developerConsole}`;
    }
  }
}
