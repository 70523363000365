import { ApiClient } from "./api/client";
import { processResponse } from "./api/helpers";


export const downloadFileFromUrl = async (url: string | Promise<Blob>, downloadAs: string, createImageWithBlobInstead =false) => {
    let resultURL = await (typeof url == 'string' ? (await fetch(url as string)).blob() : (url as Promise<Blob>))
    if (!createImageWithBlobInstead) {
        const href = window.URL.createObjectURL(resultURL);
        let anchor = document.createElement('a')
        anchor.setAttribute('href', href)
        anchor.setAttribute('download', downloadAs)
        anchor.style.display = 'none'
        document.body.appendChild(anchor)
        anchor.click()
        window.URL.revokeObjectURL(href);
    } else {
       return window.URL.createObjectURL(resultURL);
    }
}

export const downloadFileFromBackend = async (url: string, saveAs: string, params = {}, createImageBlobInstead=false) => {
    let { data } = processResponse(await ApiClient.get(url, { params, responseType: 'blob' }))
    return data && downloadFileFromUrl(data as any, saveAs, createImageBlobInstead)
}