import { useContext, useEffect, useMemo, useState } from "react";
import { useLocalStorage } from "../shared/hooks";
import { AuthContext } from "./context";
import { setAuthorization } from 'Tools/api/client';
import { loadUser, requestToken, sendPasswordResetTokenToEmail, submitNewPassword, verifyPasswordResetToken } from "./api";
import { toast } from "react-toastify";



export const useAuthProvider = () => {
  let [user, setUser] = useState<User | null>(null)
  let { add, remove, getItem } = useLocalStorage()
  let [loading, setLoading] = useState(true)
  const tokenPrefix = process.env.REACT_APP_ENVIRONMENT_PREFIX || ''

  let removeUser = () => {
    setUser(null)
  }

  let signIn = (credentials: UserCredentials) => {
    return requestToken(credentials).then(async ({ token, error ,originalError,status}) => {
      let _user: User | null = null
      if (token) {
        let { access, refresh } = token
        add(tokenPrefix+'access', access)
        add(tokenPrefix+'refresh', refresh)
        setAuthorization(access)
        let { user, error: errorMessage } = await loadUser()
        error = errorMessage
        if (user) {
          setUser(user)
          _user = user
        }
      }
      return { user: _user, error,originalError,status }
    });
  }

  let signOut = () => {
    remove(tokenPrefix+'access')
    remove(tokenPrefix+'refresh')
    removeUser()

  }


  useEffect(() => {
    let accessToken = getItem(tokenPrefix+'access')

    setLoading(true)
    if (accessToken) {
      setAuthorization(accessToken)
      loadUser().then(({ user, error }) => {
        !error && setUser(user)
      })
        .finally(() => setLoading(false))
    } else {
      setLoading(false)
    }


  }, [])
  return { signIn, loading, signOut, user, setUser }
}


export const usePasswordRecovery = (user = null as User | null,options={changeSteps:false} ) => {
  let { signIn } = useAuthContext()
  let [passwordRecoveryStep, setPasswordRecoveryStep] = useState(0)
  let [loading, setLoading] = useState(false)
  let [email, setEmail] = useState('')
  let [passwordToken, setPasswordToken] = useState('')
  let [password, setPassword] = useState('')
  let [passwordConfirmation, setPasswordConfirmation] = useState('')

  let passwordErrorMessage = useMemo(() => {
    let txt=password||''
    return (password != passwordConfirmation) && passwordConfirmation.length ? 'The passwords don\'t match!'
      : password.length < 8 ? 'The password is too short' : undefined//!/\w/.test(txt) ||!/\W/.test(txt)|| !/\d+/.test(txt)?'Include a combination of symbols and numeric characters and letters':undefined
  }, [password, passwordConfirmation])

  let passwordSubmitButtonDisabled = useMemo(() => {
    return !!(passwordRecoveryStep == 3 && (passwordErrorMessage?.length ||  (password.length && !passwordConfirmation.length))) || loading
  }, [passwordErrorMessage, password, passwordRecoveryStep, loading,passwordConfirmation])

  let _sendTokenToEmail = async () => {
    setLoading(true)
    toast.loading('Sending token')
    let { error, originalError } = await sendPasswordResetTokenToEmail((user?.email || email)?.trim())
    toast.dismiss()
    toast[error ? 'error' : 'success'](error ? error : 'The token was sent', { autoClose: error ? false : undefined })
    setLoading(false)
    // !error && setPasswordRecoveryStep(2)
    return { error }
  }


  let _verifyPasswordToken = async () => {
    setLoading(true)
    toast.loading('Verifying')
    let { error } = await verifyPasswordResetToken(passwordToken.trim())
    toast.dismiss()
    toast[error ? 'error' : 'success'](error ? 'An error occured, ensure you entered a valid email or token' : 'The token was verified')
    setLoading(false)
    // !error && setPasswordRecoveryStep(3)
    return { error }
  }


  let _submitNewPassword = async () => {
    setLoading(true)
    toast.loading('Changing password')
    let { error, originalError } = await submitNewPassword(passwordToken, password)
    let message = 'An error occured, could not update your password'
    toast.dismiss()
    originalError = originalError || {}

    if ('password' in originalError)
      message = (originalError.password || [])[0] || message

    toast[error ? 'error' : 'success'](error ? message : 'Your password was updated')
    setLoading(false)
    // !error && setPasswordRecoveryStep(0)
    if (error) {
      setPassword('')
      setPasswordToken('')
      setPasswordConfirmation('')
    }
    return { error }
  }

  let peformPasswordChangAction = async (overideStage: number | undefined = undefined, authenticateOnComplete = false) => {
    let stage = isNaN(overideStage as any) ? passwordRecoveryStep : overideStage
    let error=null as any
    let {changeSteps}=options
    let res :{error:any}={} as any
    switch (stage) {
      case 1:
         res=await _sendTokenToEmail()
        changeSteps && !res.error && setPasswordRecoveryStep(s=>s+1)
        return res
      case 2:
        res=await _verifyPasswordToken()
        changeSteps && !res.error && setPasswordRecoveryStep(s=>s+1)
        return res
        case 3:
        let username = email, pwd = password;
         ({ error } = await _submitNewPassword())
        if (authenticateOnComplete && !error) {
          return signIn({ username, password: pwd })
        }
        peformPasswordChangAction(0)
        break;
      default:
        setPasswordToken('')
        setPassword('')
        setPasswordConfirmation('')
        setPasswordRecoveryStep(0)
    }
    return { error }

  }

  return {
    email,
    setEmail,
    loading,
    passwordToken,
    setPasswordToken,
    setPasswordRecoveryStep,
    passwordRecoveryStep,
    passwordErrorMessage,
    password,
    setPassword,
    passwordConfirmation,
    setPasswordConfirmation,
    passwordSubmitButtonDisabled,
    peformPasswordChangAction
  }
}

export const useAuthContext = () => useContext(AuthContext)
