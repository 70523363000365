import { useEffect, useState } from "react";
import { IGLobalDialog, str_t, type_t, variant_t } from "./context";


export const useGlobalialog = (): IGLobalDialog => {
    let [message, setMessage] = useState(undefined as str_t)
    let [icon, setIcon] = useState(undefined as str_t)
    let [variant, setVariant] = useState(undefined as variant_t)
    let [type, setType] = useState(undefined as type_t)
    let [active, setActive] = useState(false)
    let [activeChannel, setActiveChannel] = useState(undefined as str_t)



    let show = (channel: string, message: str_t, type: type_t, variant: variant_t = 'over', icon: str_t = undefined) => {

        setMessage(message)
        setType(type)
        setVariant(variant)
        setActiveChannel(channel)
        setIcon(icon)
        setActive(true)
    }
    let close = (channel: string|null) => {
        if (channel === activeChannel || channel===null) {
            setMessage(undefined)
            setType(undefined)
            setActive(false)
            setIcon(undefined)
            setVariant(undefined)
            setActiveChannel(undefined)
        }
    }
    return {
        show,
        close,
        active,
        type,
        message,
        icon,
        variant,
        activeChannel,
    }
}