import { createContext } from "react"
import { string } from "yargs"

interface AuthContext{
    user:User|null
    loading:boolean
    signIn:(credentials:UserCredentials)=>Promise<{user:User|null,error:string|null,originalError?:Record<string,any>|null,status?:any}>
    signOut:()=>void
    setUser:(user:User|null)=>void
  }

export const AuthContext=createContext<AuthContext>({
  user: null,
  loading: true,
  setUser(user: User | null) { },
 async signIn(credentials: UserCredentials){return Promise.resolve({user:null as User|null,error:null as string|null,originalError:null,status:null})},
  signOut() { }
})