import { FontWeights, Stack, Text, Icon, ActionButton } from "@fluentui/react"
import { useNavigate } from "react-router-dom"

type Props = { retry?: boolean }

export const LoadingResourceError = ({ retry = true }: Props) => {
    let navigate = useNavigate()
    return <Stack style={{ gap: '3rem', width: '100%', paddingTop: '10rem' }} verticalAlign="center" horizontalAlign="center">
        <Stack horizontalAlign="center" style={{ gap: '1rem' }}>

            <Icon iconName='Error' />
            <Text style={{ fontWeight: FontWeights.semibold, color: 'brown' }}>The resource you requested could not be displayed</Text>
        </Stack>
        <Stack horizontalAlign="center">
            <Text style={{ fontWeight: FontWeights.light }}>Your connection to the server was interrupted</Text>
            <Text style={{ fontWeight: FontWeights.light }}>or you permissions to access the resource was denied</Text>
            <Stack verticalAlign="center" horizontal style={{ gap: '2rem' }}>
                <ActionButton onClick={_ => navigate('/')}>Go home</ActionButton>
                {retry &&
                    <>
                        <Text>or</Text>
                        <ActionButton onClick={_ => window.location.reload()} iconProps={{ iconName: 'Refresh' }}>Retry</ActionButton>
                    </>
                }

            </Stack>
        </Stack>
    </Stack>
}