
// The Syngenta Stock Management System Authors (c) 2023.
// 
// All parts of this software are confidential.
// No part of this software may be exchanged, reproduced in
//  any form or exhanged without permission.
// Violation of this will lead to legal action.

// Values associated with the global application theme
import { IDatePickerStyles,IButtonStyles, IComboBoxStyles, createTheme, ISearchBoxStyles } from "@fluentui/react";

export const themeSecondary= '#EC982E';
export const themeTertiary= '#b0ddea';
export const themeDarkAlt= '#707737';
export const themePrimary='#82c8dc';
export const bodyBackground='#fafafa';
export const APP_THEME = createTheme({
  components: {
    ComboBox: {
      styles: {
        root: {
          borderRadius: '.4rem',
          borderColor:'transparent'
        }
      } as IComboBoxStyles
    },
    DefaultButton: {
      styles: {
        root: {
          borderRadius: '.4rem',
        }
      } as IButtonStyles
    },
    DatePicker: {
      styles: {
        root: {
          borderRadius: '.7rem',
        }
      } as IDatePickerStyles
    },
    SearchBox: {
      styles: {
        root: {
          borderRadius: '.5rem',
        }
      } as ISearchBoxStyles
    },
  },
  semanticColors: {
    bodyBackground,
    bodyFrameBackground: 'white',
    inputBorder:'#ddd',
    inputBorderHovered:'#ddd',
    buttonBorder:'#efefef'
  },
  palette: {

    themePrimary,

    themeLighterAlt: '#f9fdfe',
    themeLighter: '#e9f6f9',
    themeLight: '#d6eef4',

    themeTertiary,

    themeSecondary,

    themeDarkAlt,
    themeDark: '#6298a7',
    themeDarker: '#48707b',

    neutralLighterAlt: '#f6f6f6',
    neutralLighter: '#f2f2f2',
    neutralLight: '#e8e8e8',

    neutralQuaternaryAlt: '#d8d8d8',
    neutralQuaternary: '#cecece',

    neutralTertiaryAlt: '#c6c6c6',
    neutralTertiary: '#707737',

    neutralSecondary: '#605e5c',
    neutralSecondaryAlt: '#8a8886',

    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',

    neutralDark: '#201f1e',

    black: '#000000',

    accent: '#010066'

  }
});