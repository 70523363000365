import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import SecureLS from "secure-ls"
import { getCurrentDate, uploadFile } from "./api"
import { MRT_SortingState } from "material-react-table"
import { ActionButton, Callout, DefaultButton, FontSizes, FontWeights, Modal, PrimaryButton, Stack, Text } from "@fluentui/react"
import { downloadFileFromBackend } from "Tools/file"
import { useId } from "@fluentui/react-components"
import { FilePicker } from "Components/FilePicker/FilePicker"
import { toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import { TickContext } from "./context"
import { TOAST_API } from "Tools/ui"
import { useAuthContext } from "Data/auth/hooks"


export const useLocalStorage = () => {
    let storage = new SecureLS({ encodingType: 'aes' })

    let add = (key: string, value: string) => storage.set(key, value)

    let remove = (key: string) => storage.remove(key)

    let getItem = (key: string) => storage.get(key)

    return { add, remove, getItem }
}


export const useNow = (invoke = true) => {
    let [now, setNow] = useState(new Date)
    let updateNow = useCallback(() => {
        return getCurrentDate().then(({ data }) => {
            let v = (data ? new Date(data) : now)
            setNow(v);
            return v
        })
    }, [now])
    useEffect(() => {
        invoke &&
            updateNow()
    }, [])
    return { now, updateNow }
}

export const useTick = () => {
    let [tick, setTick] = useState(0)
    return { tick, setTick }
}

export const useTickContext = () => {
    const context = useContext(TickContext)
    return context
}

export const useResolver = (onFinish?: () => any) => {
    let [loading, setLoading] = useState(false)
    async function resolve<T = any>(promise: Promise<api_res_t<T[]>>, successMessage = 'Saved changes', errorMessage = null as string | null, loadingMessage = 'Saving changes') {
        let res = await TOAST_API.resolve(promise, successMessage, errorMessage, loadingMessage)
        setLoading(false)
        onFinish?.call(null)
        return res
    }

    return { loading, resolve }
}
export const useProcessingProvide = () => {

    let [$savingData, $setSavingData] = useState(false)
    let [$isLoading, $setIsLoading] = useState(false)
    let [$errorOccured, $setErrorOccured] = useState<string | false>(false)
    let [$dataSaveFailed, $setDataSaveFailed] = useState<string | false>(false)
    let [$dataSavedSuccesfully, $setDataSavedSuccesfully] = useState<boolean>(false)

    return {
        $savingData,
        $setSavingData,
        $isLoading,
        $setIsLoading,
        $errorOccured,
        $setErrorOccured,
        $dataSaveFailed,
        $setDataSaveFailed,
        $dataSavedSuccesfully,
        $setDataSavedSuccesfully
    }
}

interface TableOptions<T, V = any, K = any> {
    noBlankFilter?: boolean
    exportedFileName?: string | (() => string)
    globalFilterName?: string
    exportDataUrl?: string
    transformList?: (data: T[]) => K[],
    fileUploadUrl?: string
    defaultPageSize?: undefined | number,
    fileUploadSuccessParser?: (data: any) => string
    mapList?: (data: T, i?: number, a?: T[]) => V
}

export type biased_response_t<T> = ({ list: T[] | null } | { data: T | null }) & { error: null | string }
type naive_response_t<T> = { list: T[] | null, data: T | null, count: number, meta?: any, next?: string } & { error: null | string }




/**
 * A convenience hook for interacting with `<MaterialReactTable/>` complete with pagination and filtering options
 * @param fetchFunction A function which calls the api returning the required table data
 * @param props Additional optional properties
 * @param deps Dependencies
 * @returns An object which can be used with `<MaterialReactTable/>
 */
export const useTable = <T, M = any, N = any, META = any>(fetchFunction?: (filters: any) => Promise<biased_response_t<T>>, props = { noBlankFilter: false, globalFilterName: undefined } as TableOptions<T, M, N>, deps = [] as any[]) => {
    let [filters, setFilters] = useState<Record<strnum_t, any>>({})
    let [data, setData] = useState([] as T[])
    let [meta, setMeta] = useState<META>(null as META)
    let [mappedData, setMappedData] = useState([] as M[])
    let [transformedData, setTransformedData] = useState([] as N[])
    let [paginationUrls, setPaginationUrls] = useState<mui_pagination_urls_t>({} as any)
    let [sorting, setSorting] = useState<MRT_SortingState>([])
    let [globalFilter, setGlobalFilter] = useState('')
    let [rowCount, setRowCount] = useState(0)// Number of items in the database matching the provided query
    let [globalFilterShown, setGlobalFilterShown] = useState(false)
    let [dataIsLoading, setDataIsLoading] = useState(false)
    let [columnFilters, setColumnFilters] = useState<{ id: any, value: any }[]>([])
    let [dataExportOptionsShown, setDataExportOptionsShown] = useState(false)
    let [fileUploadModalShown, setFileUploadModalShown] = useState({} as { file?: File | null, uploading?: boolean, shown?: boolean })
    let [pagination, setPagination] = useState<mui_pagination_t>({ pageIndex: 0, pageSize: props.defaultPageSize || 40 });
    let [exportFileName, setExportFileName] = useState('')
    let [fileToUpload, setFileToUpload] = useState(null as File | undefined | null)
    let [loadCounter, setLoadCounter] = useState(0)
    let [showColumnFilters, onShowColumnFiltersChange] = useState(false)
    let [_globalFilterTimeout, _setGlobalFilterTimeout] = useState(null as any)
    let [_globalFilter, _setGlobalFilter] = useState('')
    let tableId = useId('cpd-')

    let loading = useMemo(() => {
        return dataIsLoading || fileUploadModalShown.uploading
    }, [dataIsLoading, fileUploadModalShown.uploading])

    let _forceFetch = useCallback(() => {
        let _globalFilterLocalRef = {} as any
        let _filters = { ...filters, page: pagination.pageIndex + 1, pageSize: pagination.pageSize }
        let _columnFilters = {} as any
        columnFilters.forEach(({ id, value }) => {
            if (Array.isArray(value)) {
                _columnFilters[id.replaceAll(/\./g, '__') + '_gte'] = value[0];
                _columnFilters[id.replaceAll(/\./g, '__') + '_lte'] = value[1];
            } else
                _columnFilters[id.replaceAll(/\./g, '__')] = value;
        })
        if (props.globalFilterName && globalFilterShown) {
            _globalFilterLocalRef[props.globalFilterName] = _globalFilter
            _filters.page = undefined as any
        }


        if (fetchFunction) {
            setDataIsLoading(true)
            let sorting_ = [] as string[]
            for (let { id, desc } of [...sorting])
                sorting_.push(`${(desc ? '' : '-')}${id.replaceAll(/\./g, '__')}`)
            fetchFunction({ ..._filters, ..._columnFilters, sorting: sorting_.join(), ...(_globalFilterLocalRef) })
                .then((res) => {
                    let { list, count, meta: meta_ } = res as naive_response_t<T>
                    let pageSize = list?.length || 0
                    let list_ = list || []
                    setData(list_)
                    setMappedData(props.mapList ? list_.map<M>(props.mapList) : [])
                    setTransformedData(props.transformList ? props.transformList(list_ || []) : [])
                    // setPagination({...pagination})
                    setMeta(meta_)
                    setRowCount(count || pageSize)
                }).finally(() => setDataIsLoading(false))
        }
    }, [filters, pagination, columnFilters, globalFilterShown, sorting])


    let _uploadFile = async (file: File) => {
        let error = null as any, res = null as any
        if (!file) return toast.error('Select a file first')
        toast.loading('Uploading file')
        setFileUploadModalShown({ ...fileUploadModalShown, uploading: true });
        ({ error, data: res } = await uploadFile(props.fileUploadUrl!, file!));
        toast.dismiss()
        toast[error ? 'error' : 'success'](error || `Successfuly uploaded. ${props.fileUploadSuccessParser?.call(null, res)}`)
        setFileUploadModalShown({ ...fileUploadModalShown, uploading: false });
        if (!error) {
            _forceFetch()
            setFileToUpload(null)
            setFileUploadModalShown({})
        }
    }


    let exportToExcel = useCallback(() => {
        let { exportedFileName: propFileName = '', exportDataUrl = '' } = props || {}
        let filename = exportFileName || (typeof propFileName == 'function' ? propFileName() : propFileName)
        setDataExportOptionsShown(false)
        downloadFileFromBackend(
            exportDataUrl || '',
            `${filename || 'exported-document'}.xlsx`,
            { data_format: "excel", ...filters }
        );
    }, [filters, exportFileName])

    let ExportButton = useMemo(() => {
        type props_t = {
            disabled?: boolean
            annotation?: string | ''
        }
        return (props: props_t) => <ActionButton disabled={props.disabled} id={'exports-' + tableId} onClick={(_) => setDataExportOptionsShown(true)} iconProps={{ iconName: "Share" }}>Export {props.annotation}</ActionButton>
    }, [tableId])
    
    let FileUploadModal = useMemo(() => {
        return () => {
            return <>
                <Modal
                    dragOptions={{ menu: () => <></>, moveMenuItemText: 'Move', closeMenuItemText: 'Close' }}
                    styles={{ main: { minWidth: '400px' } }}
                    isOpen={!!fileUploadModalShown.shown}
                    isClickableOutsideFocusTrap={false}
                    onDismiss={_ => setFileUploadModalShown({ file: fileUploadModalShown.file })}
                    isModeless>
                    <Stack style={{ padding: '1rem' }}>
                        <Text style={{ fontSize: FontSizes.large, fontWeight: FontWeights.semibold }}>Select a file</Text>

                        <FilePicker file={fileToUpload} accept=".csv,.xlsx" style={{ marginTop: '2rem', width: '100%', height: 200, marginInline: 'auto' }} onFileChanged={file => { file && setFileToUpload(file) }} />


                        <Stack horizontal horizontalAlign="end" style={{ gap: '1rem', paddingTop: '3rem' }}>
                            <DefaultButton disabled={loading} onClick={_ => setFileUploadModalShown({})} style={{ alignSelf: 'end' }}>Cancel</DefaultButton>
                            <PrimaryButton disabled={loading || !fileToUpload} onClick={_ => _uploadFile(fileToUpload!)} style={{ alignSelf: 'end' }}>Upload</PrimaryButton>
                        </Stack>
                    </Stack>
                </Modal></>
        }

    }, [loading, fileUploadModalShown.shown, fileToUpload])

    let FileUploadButton = useMemo(() => {
        type props_t = { disabled?: boolean }
        return (props: props_t) => <ActionButton disabled={loading || props.disabled} onClick={(_) => setFileUploadModalShown({ shown: true })} iconProps={{ iconName: "Upload" }}>File upload</ActionButton>
    }, [])
    let ExportOptionsDropdown = useMemo(() => {
        return () => <>{!!dataExportOptionsShown && <Callout
            isBeakVisible={true}
            dismissOnTargetClick
            setInitialFocus
            onDismiss={() => setDataExportOptionsShown(false)}
            style={{ padding: "0" }}
            target={'#exports-' + tableId}
        >
            <ActionButton onClick={exportToExcel} iconProps={{ iconName: "ExcelDocument" }}>Excel</ActionButton>
        </Callout>}</>

    }, [dataExportOptionsShown, tableId])
    let ShowColumnFiltersToggle = useMemo(() => {
        return () =>
            <ActionButton onClick={_ => onShowColumnFiltersChange(!showColumnFilters)} iconProps={{ iconName: "BarChartVerticalFilter" }}>{showColumnFilters ? 'Close' : 'Open '} Filters</ActionButton>

    }, [showColumnFilters])

    let forceFetch = () => {
        setLoadCounter(c => c + 1)
    }



    useEffect(() => {
        setPagination({ ...pagination, pageIndex: 0 })
    }, [columnFilters, globalFilter])

    useEffect(() => {
        let { noBlankFilter } = props
        noBlankFilter = noBlankFilter != undefined && noBlankFilter
        if (noBlankFilter && !Object.keys({ ...columnFilters, ...filters }).length) return setData([])
        _forceFetch()
    }, [filters, loadCounter, columnFilters, sorting, pagination, _globalFilter, globalFilterShown, ...deps])

    useEffect(() => {
        clearTimeout(_globalFilterTimeout)
        _setGlobalFilterTimeout(setTimeout(() => _setGlobalFilter(globalFilter || '')))
    }, [globalFilter])
    return {
        forceFetch,
        sorting,
        meta,
        rowCount,
        loading,
        clearData: () => setData([]),
        setData,
        ExportOptionsDropdown,
        ExportButton,
        FileUploadModal,
        FileUploadButton,
        setExportFileName,
        setRowCount,
        setSorting,
        filters,
        setFilters,
        columnFilters,
        setColumnFilters,
        globalFilter,
        setGlobalFilter,
        paginationUrls,
        setPaginationUrls,
        pagination,
        data,
        mappedData,
        transformedData,
        setPagination,
        globalFilterShown,
        setGlobalFilterShown,
        showColumnFilters,
        onShowColumnFiltersChange,
        ShowColumnFiltersToggle
    }

}

export const useFSCountriesLed = (module_name: string) => {
    let { user } = useAuthContext()
    return (user?.fs_ih_countries_led || {})[module_name] as Country[] || undefined
}


interface FetchOptions<K, M> {
    invoke?: boolean
    /**Pick a single item and assign it to `data` */
    getOne?: (a: K[]) => K
    map?: (v: K, i?: number, a?: K[]) => M
    callbackMapped?: (res: M[]) => void
    aggregatedPagesDistinctFilter?: (v: K, i?: number, a?: K[]) => boolean
    afterFetch?: (a: K[]) => void
    applyPaginationOptions?: boolean
}
/**
 * The objective is to reduce on the code involved using isolated state variables and hooks
 * @param func Function invocked to get the resource 
 * @param invoke Whether or not `func` should be called. There are instances when we would not like to call `func` due to some condition
 * @param deps Dependencies 
 * @returns A state object with `list` and `data` fields holding the results
 */
export const useFetch = <T, M = T, P = Record<strnum_t, any>>(func: (params: P) => Promise<biased_response_t<T>>, deps = [] as any[], options?: FetchOptions<T, M>) => {
    let [error, setError] = useState(null as string | null)
    let [data, setData] = useState(null as T | null)
    let [list, setList] = useState(null as T[] | null)
    let [loadedPagesContent, setLoadedPagesContent] = useState([] as T[])
    let [currentPageIndex, setCurrentPageIndex] = useState(null as number | null)
    let [nextPageIndex, setNextPageIndex] = useState(null as number | null)
    let [list$, setList$] = useState([] as T[])
    let [mapedList$, setMapedList$] = useState([] as M[])
    let [loading, setLoading] = useState(false)
    let [params, setParams] = useState<P>({} as P)
    let [callback, setCallback] = useState<((l: T[] | null, d: T|null, e: any) => void) | null>(null)
    let [count, setCount] = useState(0)
    let options_ = useMemo(() => {
        return { ...options, invoke: options?.invoke !== undefined ? !!options.invoke : true } as FetchOptions<T, M>
    }, [options])

    /**
     * Force a new call to the function to happen
     * @param force Overides the option `invoke`
     */
    let load = (force = false) => {
        if (force || options_.invoke || Object.keys(params as any).length) {
            setLoading(true)
            func({ ...params, ADAPT_PAGINATION: !!options?.applyPaginationOptions || undefined }).then(result => {
                let { list = null, data = null, error, count, next } = result as naive_response_t<T>
                let mapped = options_?.map ? (list || []).map(options_.map) : []

                callback?.(list, data, error)
                setCallback(null)
                setList(list || null)
                setError(error || null)
                setData(list?.length && options_.getOne ? options_.getOne(list) : data)
                setList$(list || [])
                setCount(count || list?.length || 0)
                options?.map && setMapedList$(mapped)
                options?.callbackMapped && options.map && options.callbackMapped(mapped)
                options?.afterFetch?.(list || [])


                if (options?.applyPaginationOptions) {
                    let url = next ? new URL(next) : null
                    let nextPage = url ? new URLSearchParams(url.search).get('page') : null
                    setLoadedPagesContent(list || [])
                    if (nextPage && /\d+/.test(nextPage || ''))
                        setNextPageIndex(+nextPage)
                    else setNextPageIndex(null)
                }


            }).finally(() => {
                setLoading(false)
            })
        }
    }
    let loadPage = (page: number | undefined | null) => {
        func({ ...params, page }).then(res => {
            let { list = null, data = null, error, count, next } = res as naive_response_t<T>
            let url = next ? new URL(next) : null
            let nextPage = url ? new URLSearchParams(url.search).get('page') : null
            let concated = loadedPagesContent.concat(list || [])
            if (options?.aggregatedPagesDistinctFilter) {
                concated = concated.filter(options?.aggregatedPagesDistinctFilter)
            }
            else concated = concated.filter((v: any, i, a: any[]) => {
                return a.findIndex(v1 => v1?.id == v?.id) == i
            })
            if (error) {
                return setLoadedPagesContent([])
            }
            setLoadedPagesContent(concated)
            setCurrentPageIndex(page as any)

            if (nextPage && /\d+/.test(nextPage || ''))
                setNextPageIndex(+nextPage)
            else setNextPageIndex(null)
        })
    }
    let resetData = () => {
        setList(null)
        setList$([])
        setError(null)
        setLoading(false)
        setData(null)
    }
    useEffect(() => {
        load()
    }, [...deps, params])

    return {
        load,
        loading,
        setLoading,
        setParams,
        resetData,
        mapedList$,
        error,
        list,
        list$,
        data,
        setData,
        setList,
        params,
        count,
        loadPage,
        nextPageIndex,
        loadedPagesContent,
        currentPageIndex,
        callback,
        setCallback
    }
}
